import {RoutePermittedRole} from '@mj/constants/AppEnums';
import React from 'react';

const Employees = React.lazy(() => import('./Employee/Employee/List'));
const EmployeesCreate = React.lazy(() =>
  import('./Employee/Employee/CreateEmployee'),
);
const Role = React.lazy(() => import('./Employee/Role/CreateForm'));
const RoleList = React.lazy(() => import('./Employee/Role/List'));
const CompanyNList = React.lazy(() => import('./Company/Settings'));
const CompanyN = React.lazy(() => import('./Company/Settings/MainForm'));
const Whatsapp = React.lazy(() => import('./whatsappTemp/Main'));
const WhatsappList = React.lazy(() => import('./whatsappTemp'));
const WhatsappMsg = React.lazy(() => import('./whatsappMessage/Main'));
const WhatsappMsgList = React.lazy(() => import('./whatsappMessage'));
const CompanyB = React.lazy(() => import('./Company'));
export const rootConfig = [
  {
    permittedRole: RoutePermittedRole.whatsapp,
    path: '/apps/whatsapp/template',
    element: <WhatsappList />,
  },
  {
    permittedRole: RoutePermittedRole.whatsapp,
    path: [
      '/apps/whatsapp/messages/workspace',
      '/apps/whatsapp/messages/workspace/:id',
    ],
    element: <WhatsappMsg />,
  },
  {
    permittedRole: RoutePermittedRole.whatsapp,
    path: '/apps/whatsapp/messages',
    element: <WhatsappMsgList />,
  },
  {
    permittedRole: RoutePermittedRole.whatsapp,
    path: [
      '/apps/whatsapp/template/workspace',
      '/apps/whatsapp/template/workspace/:id',
    ],
    element: <Whatsapp />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: ['/settings/company'],
    element: <CompanyNList />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: ['/settings/company/branch', '/settings/company/branch/:id'],
    element: <CompanyB />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: [
      '/settings/company/branch/workspace',
      '/settings/company/branch/workspace/:id',
    ],
    element: <CompanyN />,
  },
  {
    permittedRole: RoutePermittedRole.permission,
    path: '/settings/permission',
    element: <RoleList />,
  },
  {
    permittedRole: RoutePermittedRole.permission,
    path: [
      '/settings/permission/workspace',
      '/settings/permission/workspace/:id',
    ],
    element: <Role />,
  },

  {
    permittedRole: RoutePermittedRole.employee,
    path: '/settings/user',
    element: <Employees />,
  },

  {
    permittedRole: RoutePermittedRole.admin,
    path: [
      '/settings/user/workspace',
      '/settings/user/workspace/:id',
      '/settings/user/profile/:id',
    ],
    element: <EmployeesCreate />,
  },
];
