import styled from 'styled-components';

export const StyledAppLogo = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  & img {
    height: 100%;
    width: 30%;
    margin-right: 10px;
    aspect-ratio: 4/1;
    object-fit: contain;
    display: block;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    & img {
      aspect-ratio: 3/4;
      object-fit: cover;
      object-position: left;
      margin-left: 10px;
      width: 40%;
    }

    [dir='rtl'] & img {
      margin-left: 0;
      margin-right: 10px;
    }
  }
`;
